import React, { Component } from "react";
import { HashLoader } from "react-spinners";

class SpinnerCmp extends Component {
  render() {
    return (
      <>
        <div
          style={{
            textAlign: "center",
            marginTop: "30vh",
            marginLeft: "90vh",
            display: this.props.loading === false ? "none" : "block",
            alignContent: "center",
          }}
        >
          <HashLoader color={"#adc927"} loading={this.props.loading} />
        </div>
      </>
    );
  }
}

export default SpinnerCmp;
