import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Link } from "react-router-dom";
import Footer from "../../component/Footer";
import "../../assets/new/css/Forum.css";
import "../../assets/new/css/MyDiary.css";
import "../../assets/new/css/SchoolTvChannel.css";
import "../../assets/new/css/library.css";
import "../../assets/new/css/wallet.css";

const TransactionHis = () => {
  const listData = [
    {
      id: 1,
      date: "12-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: " $300",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 2,
      date: "12-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$80",
      simple: "-",
      color: "red",
    },
    {
      id: 3,
      date: "11-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$100",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 4,
      date: "09-Aug-2023",
      upi: "UPI/3453453454/UPI",
      color: "red",
      amt: "$200",
      simple: "-",
      color: "red",
    },
    {
      id: 5,
      date: "09-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$300",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 6,
      date: "08-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$200",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 7,
      date: "08-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$450",
      simple: "-",
      color: "red",
    },
    {
      id: 8,
      date: "07-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$50",
      simple: "+",
      color: "#72A44B",
    },
  ];
  return (
    <>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <div className="page-body-wrapper sidebar-icon">
          <div className="page-body" id="container">
            <div class="Forum library">
              <div className="main-section">
                <div className="row">
                  {listData.map((item, index) => (
                    <div className="col-md-4" key={index}>
                      <div className="d-flex justify-content-between flex-row p-2 my-1 rounded-3 shadow-sm bg-light">
                        <div>
                          <p
                            style={{
                              color: "#241549",
                              fontSize: "16px",
                            }}
                          >
                            {item.date}
                          </p>
                          <p
                            style={{
                              color: "#857E84",
                              fontSize: "14px",
                            }}
                          >
                            {item.upi}
                          </p>
                        </div>

                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              color: item.color,
                              fontSize: "16px",
                            }}
                          >
                            {item.simple}
                          </p>
                          <p
                            style={{
                              color: "#1E0A1B",
                              fontSize: "16px",
                            }}
                          >
                            {item.amt}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TransactionHis;
