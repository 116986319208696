import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import Params from "./config/Params";
import authContext from "./layout/GetUserStatus";

const LoginPage = React.lazy(() => import("./pages/loginPage/LoginPage"));

const App = () => {
  const [UserProfile, setUserProfile] = useState({});
  const [profile, setProfile] = useState("");
  console.log(UserProfile, "========>");
  let LayoutCmp;
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("is_logged_in"))
  );
  if (authenticated) {
    LayoutCmp = <Layout />;
  } else {
    LayoutCmp = (
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="*" element={<LoginPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }

  return (
    <authContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        UserProfile,
        setUserProfile,
        setProfile,
        profile,
      }}
    >
      {LayoutCmp}
    </authContext.Provider>
  );
};

export default App;
