import React, { Suspense } from "react";
import Header from "../component/Header";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  Route,
  Switch,
  Routes,
  HashRouter,
} from "react-router-dom";
import UserProfile from "../component/UserProfile";

import Demo from "../pages/Demo.js";
import CashlessWallet from "../pages/CashWallet/CashlessWallet.js";
import TransactionHis from "../pages/CashWallet/TransactionHis.js";
import TrackSchoolBus from "../pages/TrackBus/TrackSchoolBus.js";
import AuthorizedPerson from "../pages/TrackBus/AuthorizedPerson.js";
const StuTracking = React.lazy(() => import("../pages/Tracking/StuTracking"));
const ExplorePage = React.lazy(() =>
  import("../pages/explorePage/Explorepage"),
);
const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));
const Elearning = React.lazy(() => import("../pages/eLearning/Elearning"));
const ElearningList = React.lazy(() =>
  import("../pages/eLearning/ElearningList"),
);
const ElearningTextPage = React.lazy(() =>
  import("../pages/eLearning/eLearningFiles/ElearningTextPage"),
);
const RouteMap = React.lazy(() => import("../pages/Route/RouteMap.js"));
const DummyMap = React.lazy(() => import("../pages/Route/DummyMap.js"));
const ElearningGoogleDrivePage = React.lazy(() =>
  import("../pages/eLearning/eLearningFiles/ElearningGoogleDrivePage"),
);
const ElearningPdfPage = React.lazy(() =>
  import("../pages/eLearning/eLearningFiles/ElearningPdfPage"),
);
const ElearningPptPage = React.lazy(() =>
  import("../pages/eLearning/eLearningFiles/ElearningPptPage"),
);
const ElearningVideoPage = React.lazy(() =>
  import("../pages/eLearning/eLearningFiles/ElearningVideoPage"),
);
const ElearningYoutubePage = React.lazy(() =>
  import("../pages/eLearning/eLearningFiles/ElearningYoutubePage"),
);
const YourActivities = React.lazy(() =>
  import("../pages/yourActivities/YourActivities"),
);
const Teachers = React.lazy(() => import("../pages/teachers/Teachers"));
const CourseContent = React.lazy(() => import("../pages/CourseContent"));
const ExploreCourse = React.lazy(() =>
  import("../pages/explorePage/ExploreCourse"),
);
const ExploreCourseDetails = React.lazy(() =>
  import("../pages/explorePage/ExploreCourseDetails"),
);
const Assignments = React.lazy(() =>
  import("../pages/assignments/Assignments"),
);
const Assessments = React.lazy(() =>
  import("../pages/assessments/Assessments"),
);
const AssignmentStart = React.lazy(() =>
  import("../pages/assignments/AssignmentStart"),
);
const AssessmentInstructions = React.lazy(() =>
  import("../pages/assessments/AssessmentInstructions"),
);
const AssessmentStart = React.lazy(() =>
  import("../pages/assessments/AssessmentStart"),
);
const TeachersDetails = React.lazy(() =>
  import("../pages/teachers/TeachersDetails"),
);
const AttendanceFullTimeline = React.lazy(() =>
  import("../pages/attedance/AttendanceFullTimeline"),
);
const CalendarPage = React.lazy(() => import("../pages/calendar/Calendar"));
const MyCart = React.lazy(() => import("../pages/myCart/MyCart"));
const MyWallet = React.lazy(() => import("../pages/myWallet/MyWallet"));
const MyBalance = React.lazy(() => import("../pages/myWallet/MyBalance"));
const MyStatements = React.lazy(() => import("../pages/myWallet/MyStatements"));
const MyWalletNotifications = React.lazy(() =>
  import("../pages/myWallet/MyWalletNotifications"),
);
const MyNotification = React.lazy(() => import("../pages/MyNotification"));
const NotificationSettings = React.lazy(() =>
  import("../pages/NotificationSettings"),
);
const OpenChat = React.lazy(() => import("../pages/OpenChat"));
const MyCourses = React.lazy(() => import("../pages/MyCourses"));
const Attendance = React.lazy(() => import("../pages/attedance/Attendance"));
const ApplyLeave = React.lazy(() => import("../pages/myLeaves/ApplyLeave"));
const ViewLeave = React.lazy(() => import("../pages/myLeaves/ViewLeave"));
const SubjectSyllabus = React.lazy(() => import("../pages/SubjectSyllabus"));

const WalletPaytmPayment = React.lazy(() =>
  import("../pages/myWallet/PaytmPayment"),
);
const WalletRazorpayPayment = React.lazy(() =>
  import("../pages/myWallet/RazorpayPayment"),
);
const PrintReceipt = React.lazy(() => import("../pages/myWallet/PrintReceipt"));
const WalletPayubizPayment = React.lazy(() =>
  import("../pages/myWallet/PayubizPayment"),
);
const WalletPayubizSuccess = React.lazy(() =>
  import("../pages/myWallet/PayubizSuccess"),
);
const WalletPayubizFailed = React.lazy(() =>
  import("../pages/myWallet/PayubizFailed"),
);
const WalletPayumoneyPayment = React.lazy(() =>
  import("../pages/myWallet/PayumoneyPayment"),
);
const WalletPayfortPayment = React.lazy(() =>
  import("../pages/myWallet/PayfortPayment"),
);
const NoteView = React.lazy(() => import("../pages/MyDiary/NoteView.js"));

const StudentChat = React.lazy(() => import("../pages/Commuincation/Chat"));

const ToDo = React.lazy(() => import("../pages/ToDo/ToDo"));
const ToDoDetails = React.lazy(() => import("../pages/ToDo/ToDoDetails"));

const NoticeBoard = React.lazy(() =>
  import("../pages/NoticeBoard/NoticeBoard"),
);
const NoticeBoardDetail = React.lazy(() =>
  import("../pages/NoticeBoard/NoticeBoardDetail"),
);
const Library = React.lazy(() => import("../pages/Library/Library"));

// CHAT
// import Chat from "../pages/Commuincation/Chat";

// // ToDo
// import ToDo from "../pages/ToDo/ToDo";const MyDiary = React.lazy(() => import("../pages/MyDiary/MyDiary.js"));
const MyDiary = React.lazy(() => import("../pages/MyDiary/MyDiary.js"));
const Forum = React.lazy(() => import("../pages/Forum/Forum.js"));
const ForumView = React.lazy(() => import("../pages/Forum/ForumView.js"));
const Feed = React.lazy(() => import("../pages/Feed/Feed.js"));
const FeedView = React.lazy(() => import("../pages/Feed/FeedView.js"));
// const CashlessWallet = React.lazy(() =>
//   import("../../src/pages/CashWallet/CashlessWallet.js")
// );
// const TransactionHis = React.lazy(() =>
//   import("../pages/CashWallet/TransactionHis.js")
// );

const Layout = () => {
  const { t } = useTranslation();
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="*" element={<Dashboard />} />
          <Route exact path="/student" element={<Dashboard />} />
          <Route exact path="/student/dashboard" element={<Dashboard />} />
          <Route exact path="/student/explore" element={<ExplorePage />} />
          <Route exact path="/explore-courses" element={<ExploreCourse />} />
          <Route exact path="/UserProfile" element={<UserProfile />} />
          <Route exact path="/StuTracking" element={<StuTracking />} />
          <Route
            path="/explore-course-details"
            element={<ExploreCourseDetails />}
          />
          <Route path="/e-learning" element={<Elearning />} />
          <Route path="/e-learning-list" element={<ElearningList />} />
          <Route path="/e-learning-text" element={<ElearningTextPage />} />
          <Route path="/e-learning-video" element={<ElearningVideoPage />} />
          <Route path="/e-learning-pdf" element={<ElearningPdfPage />} />
          <Route path="/e-learning-ppt" element={<ElearningPptPage />} />
          <Route
            path="/e-learning-youtube"
            element={<ElearningYoutubePage />}
          />
          <Route
            path="/e-learning-googledrive"
            element={<ElearningGoogleDrivePage />}
          />
          <Route path="/your-activities" element={<YourActivities />} />
          <Route path="/teachers" element={<Teachers />} />
          <Route
            path="/teachers-details/:teacher_id"
            element={<TeachersDetails t={t} />}
          />
          <Route path="/course-content" element={<CourseContent />} />
          <Route path="/assignments" element={<Assignments />} />
          <Route path="/assessments" element={<Assessments />} />
          <Route
            path="/assignment-start/:assignment_id"
            element={<AssignmentStart />}
          />
          <Route
            path="/assessment-instructions/:assessment_id"
            element={<AssessmentInstructions t={t} />}
          />
          <Route
            path="/assessment-start/:assessment_id"
            element={<AssessmentStart t={t} />}
          />
          <Route
            path="/attendance-timeline"
            element={<AttendanceFullTimeline t={t} />}
          />
          <Route path="/calendar" element={<CalendarPage />} />
          <Route path="/my-cart" element={<MyCart />} />
          <Route path="/my-wallet" element={<MyWallet />} />
          <Route path="/my-balance" element={<MyBalance />} />
          <Route path="/my-statements" element={<MyStatements />} />
          <Route
            path="/my-wallet-notifications"
            element={<MyWalletNotifications />}
          />
          <Route path="/notification" element={<MyNotification />} />
          <Route path="/RouteMap" element={<RouteMap />} />{" "}
          <Route path="/DummyMap" element={<DummyMap />} />
          <Route
            path="/notification-settings"
            element={<NotificationSettings />}
          />
          <Route path="/chat" element={<OpenChat />} />
          <Route path="/my-courses" element={<MyCourses />} />
          <Route path="/attendance" element={<Attendance />} />
          <Route path="/apply-leave" element={<ApplyLeave />} />
          <Route path="/view-leave" element={<ViewLeave />} />
          <Route
            path={"/subject-syllabus/:subject_id"}
            element={<SubjectSyllabus />}
          />
          <Route
            path="/wallet/paytm-payment"
            element={<WalletPaytmPayment />}
          />
          <Route
            path="/wallet/razorpay-payment"
            element={<WalletRazorpayPayment />}
          />
          <Route path="/wallet/print-receipt" element={<PrintReceipt />} />
          <Route
            path="/wallet/payubiz-payment"
            element={<WalletPayubizPayment />}
          />
          <Route
            path="/wallet/payubiz-success"
            element={<WalletPayubizSuccess />}
          />
          <Route
            path="/wallet/payubiz-failed"
            element={<WalletPayubizFailed />}
          />
          <Route
            path="/wallet/payment-success"
            element={<WalletPayubizSuccess />}
          />
          <Route
            path="/wallet/payment-failed"
            element={<WalletPayubizFailed />}
          />
          <Route
            path="/wallet/payumoney-payment"
            element={<WalletPayumoneyPayment />}
          />
          <Route
            path="/wallet/payfort-payment"
            element={<WalletPayfortPayment />}
          />
          <Route path="/StudentChat" element={<StudentChat />} />
          <Route path="/ToDo" element={<ToDo />} />
          <Route path="/ToDoDetails" element={<ToDoDetails />} />
          <Route path="/NoticeBoard" element={<NoticeBoard />} />
          <Route
            path="//NoticeBoardDetail/:id/:assignment_title"
            element={<NoticeBoardDetail />}
          />
          <Route path="/MyDiary" element={<MyDiary />} />
          <Route path="/NoteView" element={<NoteView />} />
          <Route path="/Forum" element={<Forum />} />
          <Route path="/Feed" element={<Feed />} />
          <Route path="/FeedView" element={<FeedView />} />
          <Route path="/ForumView" element={<ForumView />} />
          <Route path="/CashlessWallet" element={<CashlessWallet />} />
          <Route path="/TransactionHis" element={<TransactionHis />} />
          <Route path="/Demo" element={<Demo />} />
          <Route path="/TrackSchoolBus" element={<TrackSchoolBus />} />
          <Route path="/AuthorizedPerson" element={<AuthorizedPerson />} />
          <Route path="/Library" element={<Library />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Layout;
