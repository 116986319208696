const AppStrings = {
  DEVICE_TOKEN: "device_token",
  DEVICEID: "device_id",
  //asyncStorage keys and values
  CUSTOMER_ID: "customer_id", //"customer_id"
  API_KEY: "api_key", //"api_key"
  STUDENT_ID: "student_id", //"student_id"
  SUBJECT_ID: "subject_id", //"subject_id",
  ASSIGNMENT_ID: "assignment_id",
  something_went_wrong: "Something went wrong. Please try again later!",
  USER_ID: "user_id",
  DATE: "date", //"date"
  RESOURCE_ID: "resource_id", //"resource_id"
  PROFILE: "Profile",
  SYLLABUS: "Syllabus",
  RESULT: "Result",
  STUDENT_PROFILEPICTURE: "student_profilepicture",
  //login Response

  STUDENT_NAME: "student_name",
  STUDENT_ADMISSION_NUM: "student_admission_num",
  STUDENT_MI_FARE_CARD_ID: "student_mifare_card_id",
  STUDENT_PROFILE_PICTURE: "student_profilepicture",
  CLASS_NAME: "class_name",
  CLASS_DIVISION_NAME: "class_division_name",
  CUSTOMER_USERNAME: "customer_username",
  CUSTOMER_MOBILE: "customer_mobile",
  CUSTOMER_EMAIL: "customer_email",
  CUSTOMER_SUBDOMAIN_API: "customer_subdomain_erp",
  LOGO_SCHOOL: "logo_school",
  LOGO_PARENT_APP: "logo_parent_app",
  LOGO_TRIP_START_POINT: "logo_trip_start_point",
  LOGO_TRIP_END_POINT: "logo_trip_end_point",
  ACADEMIC_YEAR_START_DATE: "academic_year_start_date",
  ACADEMIC_YEAR_END_DATE: "academic_year_end_date",
  TRANSPORTATION_YEAR: "transportation_type",
  STUDENT_EMAIL: "student_email",

  //login
  emailAddr: "ENTER EMAIL ADDRESS",
  password: "PASSWORD",
  login: "REQUEST FOR OTP",
  verify: "VERIFY",
  dontHaveAcc: "Dont have an account?",
  register: "REGISTER",
  resend: "RESEND OTP",
  userName: "USERNAME",

  //header title
  yourAttendacneTimeLine: "YOUR ATTENDANCE TIMELINE",

  //Header
  search: "Search Cources",
  yourProgress: "Your Progress",
  favourite: "Favourite",
  share: "Share",
  resetProgress: "Reset Progress",
  completeCourseReward: "Complete the course to get rewards.",

  //Dashboard
  welcomeBack: "Welcome back",
  jumpBack: "Ready to jump back in?",
  pendingAssessmentText: "Your Pending Assessment",
  pendingAssignmentText: "Your Pending Assignments",
  subject: "Subject",
  title: "Title",
  action: "Action",
  attendanceTimeline: "Your Attendance Timeline",
  selectDate: "SELECT DATE",
  fullStory: "FULL STORY",

  //drawer
  settings: "Settings",
  contactUs: "Contact Us",
  termsNC: "Terms & Conditions",
  profile: "My Profile",
  profileImage: "Profile Image",
  //Account
  myCources: "My Courses",
  myFavourites: "My Favourites",
  mySchedule: "My Schedule",
  // wallet: "Wallet",
  profile: "Profile",
  paymentHistory: "Payment History",
  logout: "Logout",

  //your attendance timeline screen
  yourClassInfo: "Your Class Info",
  totalClasses: "TOTAL CLASSES",
  classesAttended: "CLASSES ATTENDED",
  classesMissed: "CLASSES MISSED OUT",
  timeline: "Timeline",

  //course details
  overview: "OVERVIEW",
  chat: "CHAT",
  workspace: "WORKSPACE",
  aboutTheCourse: "About the Course",
  seeMore: "SEE MORE",
  lastChated: "Last chated:",
  openChat: "OPEN CHAT",
  writeThings: "Write down the things your understood during the lecture",
  submit: "SUBMIT",
  writeHere: "Write here...",
  courseContent: "Course Content",
  byTheNumbers: "By the numbers",
  skillLevel: "Skill Level",
  students: "Students",
  language: "Language",
  caption: "Caption",
  lectures: "Lectures",
  hours: "Hours",
  features: "Features",
  description: "Description",

  //Assessments
  todaysAssessments: "Today's Assessments",
  upcomingAssessments: "Upcoming Assessments",
  assessmentsHistory: "Assessments History",
  sNo: "Sr.NO",
  dateNtime: "DATE & TIME",
  createdBy: "CREATED BY",
  AssessmentsDuration: "DURATION",
  instruction: "INSTRUCTIONS",
  numOfQue: "Number of Questions",
  testMustFinish:
    "Test must be finished in one sitting, you cannot save and finish later.",
  queDis: "Question displayed per page",
  studCanSel: "Student can select answer by clicking selected options again.",
  diffMarks: "All the questions have different marks.",
  studCanClick:
    "Student can click on previous button or click on particular question number displayed in a table to go to respective questions.",
  startTest: "START TEST",
  timeRemaining: "TIME REMAINING",
  attempted: "ATTEMPTED",
  skip: "SKIP",
  quitTest: "QUIT TEST",
  submitTest: "SUBMIT TEST",
  prev: "PREV",
  submit: "SUBMIT",
  pre: "PREV",
  quit: "QUIT",
  //assignments
  todaysAssignments: "Today's Assignments",
  upcomingAssignments: "Upcoming Assignments",
  assignmentsHistory: "Assignments History",
  class: "CLASS",
  submissionDate: "SUBMISSION DATE",
  status: "STATUS",
  assignmentInfo: "Assignment Info",
  taskUnderAssign: "Tasks Under Assignment",
  selectformat:
    "PLEASE SELECT THE FORMAT IN WHICH YOU WANT TO ATTEND THE ASSIGNMENT ",
  //result
  numOfQueAttempt: "Number of Questions Attempted",
  totalMarks: "Total Marks",
  percentile: "Percentile",
  obtainedMarks: "Obtained Marks",
  testQue: "Test Questions",
  correctQue: "Correct Questions",
  wrongQue: "Wrong Questions",
  skipQue: "Skipped Questions",
  duration: "Duration",
  submitDate: "Submit Date",
  downloadResult: "DOWNLOAD RESULT",

  //calendar
  clickOnLabel: "Click on the labels in the calendar for more details...",
  today: "Today",
  back: "Back",
  next: "Next",
  month: "Month",
  week: "Week",
  day: "Day",
  prev: "Previous",
  schSched: "School Schedule",
  courseStarts: "Course 1 Starts",
  courseEnds: "Course 1 Ends",
  assignment: "Assignment",
  assessment: "Assessments",
  yourSched: "Your Schedule For The Day - ",

  //Teacher api list
  customer_id: "customer_id",
  api_key: "api_key",
  student_id: "student_id",
};

export default AppStrings;
