import React from "react";
import { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <>
        <footer className="footer" id="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 footer-copyright">
                <p className="mb-0">
                  Copyright {new Date().getFullYear()} © edsys All rights
                  reserved.
                </p>
              </div>
              <div className="col-md-6">
                <p className="pull-right mb-0 ftr-contact">
                  {/* <a href="#">Contact Us</a>
                  <a href="#">Terms & Conditions</a> */}
                </p>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
export default Footer;
